
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import { exportRechargeList, getRechargeList } from "@/api/request/bigdata";

//组件
@Component({
  name: "PaymentStatement",
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求数据
  private listQuery: any = {
    //临时数据
    time: [],

    //请求数据
    end_time: "",
    start_time: "",
  };

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]}  23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await getRechargeList(this.listQuery);

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD30()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //获取数据
    this.getList();
  }

  //处理导出
  private async exportHandler() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]}`;
      this.listQuery.start_time = `${this.listQuery.time[0]}`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //获取数据
    const { data } = await exportRechargeList(this.listQuery);

    //保存数据
    saveAs(data, "支付报表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
